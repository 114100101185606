<template>
  <div class="elStep">
    <el-steps :active="active" finish-status="success" align-center>
      <el-step v-for='item in stepArr' :key='item' :title="item"></el-step>
    </el-steps>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
export default defineComponent({
  name: 'myStep',
  props:{
    active: {
       type:Number,
       default:0
    },
    stepArr:{
      type:Array,
      default:()=>{
        return []
      }
    }
  }
})
</script>
<style scoped lang="scss">
@import '@/assets/style/comman.scss';
.elStep{
  width:100%;
  ::v-deep{
    .el-step.is-center .el-step__head{
      .el-step__icon-inner{
        font-size: 12px;
      }
    }
    .el-step__title.is-process{
      font-weight: normal;
      color:#666;
      font-size: 12px;
      .el-step__line{
        background-color:#666;
      }
    }
    .el-step__head.is-success{
      color:$main-color;
      border-color:$main-color;
      .el-step__line{
        background-color:$main-color;
      }
    }
    .el-step__title.is-success{
      color:$main-color;
      font-size: 12px;
    }
    .el-step__title.is-wait{
      color:#666;
      font-size: 12px;
    }
    .el-step__head.is-wait{
      color:#666;
      border-color:#666;
      .el-step__line{
        background-color:#666;
      }
    }
    .el-step__head.is-process {
      color: #666;
      border-color: #666;
      .el-step__line{
        background-color:#666;
      }
    }
  }
  
}

</style>
