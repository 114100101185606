<template>
  <div class="personInfo">
    <el-form ref="elForm" :model="state.formData" :rules="rules" size="medium" label-width="120px"
      label-position="left">
      <el-form-item label="姓名" prop="userName">
        <el-input v-model="state.formData.userName" placeholder="请输入姓名" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio-group v-model="state.formData.sex" size="medium">
          <el-radio v-for="(item, index) in field102Options" :key="index" :label="item.value" :disabled="item.disabled">
            {{item.label}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <div :class="hasFaceImg?'box':''">
        <el-form-item label="出生日期" prop="birthday">
          <el-date-picker v-model="state.formData.birthday" :style="{width: '100%'}" placeholder="请选择出生日期" clearable>
          </el-date-picker>
        </el-form-item>
        <el-upload v-if='hasFaceImg' class="upload-demo" action="#" multiple :limit="1" :show-file-list="false" :http-request="uploadFile"
          :before-upload="beforeAvatarUpload">
          <span class="upload_txt">上传门禁照片</span>
        </el-upload>
      </div>
      <el-form-item label="手机号" prop="tel">
        <el-input v-model="state.formData.tel" placeholder="请输入手机号" clearable :style="{width: '100%'}">
          <template #suffix>
            <span class="update_tel" @click="handleUpdateTel">修改</span>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="执业律所" prop="officeName">
        <el-input v-model="state.formData.officeName" placeholder="请输入执业律所" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item label="律师所在地" prop="addr">
        <el-cascader v-model="state.formData.addr" :options="field106Options" :style="{width: '100%'}"
          placeholder="请选择律师所在地" clearable @change="changeAddr"></el-cascader>
      </el-form-item>
      <el-form-item label="执业证号" prop="licenseNo">
        <el-input v-model="state.formData.licenseNo" placeholder="请输入执业证号" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item label="首次执业时间" prop="firstPracticeDate">
        <el-date-picker v-model="state.formData.firstPracticeDate" :style="{width: '100%'}" placeholder="请选择首次执业时间"
          clearable>
        </el-date-picker>
      </el-form-item>
      <el-form-item label="擅长领域" prop="bizTypeGoodat">
        <el-select multiple v-model="state.formData.bizTypeGoodat" :style="{width: '100%'}"
          placeholder="请选择擅长领域" clearable>
          <el-option v-for="item in state.bizTypeGoodatList" :key="item.key" :label="item.value" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item size="large">
        <el-button type="primary" class="btn" @click="submitForm">保存</el-button>
      </el-form-item>
    </el-form>
    <div class="avatar" v-if='hasFaceImg'>
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#iconyonghu"></use>
      </svg>
    </div>
    <MyDialog :isShow="show" title="修改手机号" :closeDialog="closeDialog">
      <div class="tel_form">
        <MyStep :active="activeStep" :stepArr="stepArr"></MyStep>
        <el-form ref="telForm" :rules="telRules" :model="telFormData" label-width="80px" label-position="left">
          <el-form-item label="手机号" prop="tel" v-if="activeStep == 1">
            <el-input placeholder="请输入手机号" v-model="telFormData.tel">
            </el-input>
          </el-form-item>
          <el-input placeholder="请输入短信验证码" v-model="telFormData.code" v-if="activeStep == 2">
            <template #suffix>
              <div class="update_tel" @click="reGetCode">{{seconds >0 ? seconds + 's后重新获取' : '重新获取'}}</div>
            </template>
          </el-input>
          <el-button type="primary" @click="handleGetCode" class="code_btn" round>{{btnText}}</el-button>
        </el-form>
      </div>
    </MyDialog>
  </div>
</template>
<script>
  import MyDialog from '@/components/myDialog'
  import MyStep from '@/components/step'
  import {
    ElMessage
  } from 'element-plus'
  import {
    regionData,
    CodeToText,
    TextToCode
  } from 'element-china-area-data'
  import {
    getUserInfo,
    updateTel,
    save,
    getCode,
    getListByDictType
  } from '@/api/account'
  import {
    isTel
  } from '@/utils'
  import {
    defineComponent,
    reactive,
    ref,
    onMounted
  } from 'vue';
  import {
    useStore
  } from 'vuex';
  export default defineComponent({
    name: 'PersonInfo',
    components: {
      MyDialog,
      MyStep
    },
    props: {
      ossUrl: {
        type: String,
        default: ''
      }
    },
    setup(props,content) {
      let btnText = ref("获取验证码")
      let elForm = ref(null)
      let show = ref(false)
      let activeStep = ref(1)
      let timer = ref(null)
      let seconds = ref(60)
      let state = reactive({
        bizTypeGoodatList: [],
        formData: {
          userName: undefined,
          sex: undefined,
          birthday: null,
          tel: undefined,
          officeName: undefined,
          addr: [],
          licenseNo: undefined,
          firstPracticeDate: null,
          bizTypeGoodat: [],
          locationCityName: null,
          locationTownName: null,
          locationProvinceName: null,
          headPortraitUrl: null
        }
      })
      let rules = reactive({
        userName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        sex: [{
          required: true,
          message: '请选择性别',
          trigger: 'change'
        }],
        birthday: [{
          required: true,
          message: '请选择出生日期',
          trigger: 'change'
        }],
        tel: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          pattern: isTel,
          message: '手机号格式错误',
          trigger: 'blur'
        }],
        officeName: [{
          required: true,
          message: '请输入执业律所',
          trigger: 'blur'
        }],
        addr: [{
          required: true,
          type: 'array',
          message: '请选择律师所在地',
          trigger: 'change'
        }],
        licenseNo: [{
          required: true,
          message: '请输入执业证号',
          trigger: 'blur'
        }],
        firstPracticeDate: [{
          required: true,
          message: '请选择首次执业时间',
          trigger: 'change'
        }],
        bizTypeGoodat: [{
          required: true,
          type: 'array',
          message: '请选择擅长领域',
          trigger: 'change'
        }],
      })
      let telRules = reactive({
        tel: [{
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          },
          {
            pattern: isTel,
            message: '手机号格式错误',
            trigger: 'blur'
          }
        ]
      })
      let field102Options = reactive([{
        "label": "男",
        "value": 0
      }, {
        "label": "女",
        "value": 1
      }])
      let field106Options = reactive(regionData)
      let field109Props = reactive({
        "multiple": false,
        "label": "label",
        "value": "value",
        "children": "children"
      })
      let active = ref(1)
      const telFormData = reactive({
        tel: null,
        code: null
      })
      let telForm = ref(null)
      let stepArr = reactive([
        '新手机号', '短信验证'
      ]);
      const store = useStore();
      const submitForm = () => {
        console.log(elForm.value)
        elForm.value.validate(async valid => {
          if (valid) {
            state.formData.bizTypeGoodat = state.formData.bizTypeGoodat.join(',')
            // console.log('url',props.ossUrl);
            state.formData.headPortraitUrl = props.ossUrl
            let res = await save(state.formData)
            console.log(res);
            if(res.code == 200){
              ElMessage.success("保存成功");
              store.commit('getUserInfo',{
                userName:state.formData.userName,
                userTel:state.formData.tel
              });
              getInfo()
            }
          }
        })
      }
      const getGoodAreaList = async () => {
        let res = await getListByDictType({
          id: 10003
        })
        state.bizTypeGoodatList = res.data
      }
      const handleGetCode = async () => {
        if (btnText.value === '获取验证码') {
          telForm.value.validate(async (valid) => {
            if (valid) {
              activeStep.value += 1
              timer.value = setInterval(() => {
                if (seconds.value <= 0) {
                  clearInterval(timer.value)
                  btnText.value = '获取验证码'
                } else {
                  seconds.value -= 1
                }
              }, 1000);
              let res = await getCode({
                tel: telFormData.tel
              })
              if (res.code == 200) {
                btnText.value = '确定'
              }
            }
          })
        } else if (btnText.value == "确定") {
          let res = await updateTel({
            newTel: telFormData.tel
          })
          if (res.code == 200) {
            ElMessage.success("修改成功")
          }
        }

      }
      const handleUpdateTel = () => {
        show.value = true
      }
      const reGetCode = () => {
        console.log(seconds.value)
      }
      const closeDialog = () => {
        show.value = false
        activeStep.value = 1
      }
      const changeAddr = (val) => {
        // console.log(val)
        // console.log(CodeToText[val[0]]); // 省
        // console.log(CodeToText[val[1]]); // 市
        // console.log(CodeToText[val[2]]); // 区
        state.formData.locationProvinceName = CodeToText[val[0]]
        state.formData.locationCityName = CodeToText[val[1]]
        state.formData.locationTownName = CodeToText[val[2]]
      }
      const beforeAvatarUpload = (file) => {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        if (!isJPG && !isPNG) {
          ElMessage.error("上传头像图片的格式只能是jpg/png格式")
        }
        return isPNG && isJPG
      }
      const uploadFile = (file) => {
        console.log(file);
      }
      let hasFaceImg = ref('');
      const getInfo = async () => {
        let res = await getUserInfo()
        // console.log(res);
        if (res&&res.code == 200) {
          if(res.data.bizTypeGoodat){
            res.data.bizTypeGoodat = res.data.bizTypeGoodat.split(",")
          };
          hasFaceImg.value = res.data.isOpenFaceRecognition;
          let addr = []
          addr.push(res.data.locationProvinceName)
          addr.push(res.data.locationCityName)
          addr.push(res.data.locationTownName)
          state.formData = res.data
          sessionStorage.setItem("wxOpenId",res.data.wxOpenId)
          state.formData.addr = []
          // console.log(TextToCode[addr[0]].code);
          state.formData.addr.push(TextToCode[addr[0]].code)
          state.formData.addr.push(TextToCode[addr[0]][addr[1]].code)
          state.formData.addr.push(TextToCode[addr[0]][addr[1]][addr[2]].code)
          content.emit("ossUrlClick",state.formData.headPortraitUrl)
          store.commit('getUserInfo',{
            userName:res.data.userName,
            userTel:res.data.tel
          })
        }
      }
      onMounted(() => {
        getInfo()
        getGoodAreaList()
      })
      return {
        hasFaceImg,
        elForm,
        state,
        rules,
        field102Options,
        field106Options,
        field109Props,
        submitForm,
        handleGetCode,
        active,
        stepArr,
        show,
        handleUpdateTel,
        closeDialog,
        activeStep,
        telForm,
        telFormData,
        telRules,
        timer,
        seconds,
        changeAddr,
        uploadFile,
        beforeAvatarUpload,
        btnText,
        reGetCode,
      }
    }
  })
</script>
<style lang="scss" scoped>
  @import '@/assets/style/comman.scss';

  .update_tel {
    color: $main-color;
    cursor: pointer;
  }

  .personInfo {
    width: 500px;
    position: relative;

    .btn {
      width: 160px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
    }

    .box {
      display: flex;
      justify-content: space-between;

      .upload_txt {
        display: block;
        width: 100px;
        text-align: center;
        line-height: 36px;
        color: $main-color;
      }
    }

    .avatar {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      right: 0;

      .icon {
        width: 100%;
        height: 100%;
        color: #dcdfe6;
      }
    }

  }

  .tel_form {
    width: 305px;
    margin: 30px auto 50px;

    .el-form {
      margin-top: 40px;
    }

    .code_btn {
      margin-top: 30px;
      width: 100%;
    }
  }

  .elStep {
    width: 165%;
    margin-left: -100px;
  }
</style>