// 身份证
const isIdtitfy = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
// 可以不填，填则校验身份证
const isIdtitfyReg = /(^$)|^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
// 判断是否为手机号
const isTel = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
// 密码
const isPsd = /(?=.*[a-z])|(?=.*\d)(?=.*[#@!~%^&*])|[a-z\d#@!~%^&*]{6,}/i
// 日期转换yyyy-MM-dd HH:mm:ss
const transformDateTime = (dateTime)=>{
  const time = new Date(dateTime);
  let year = time.getFullYear();
  let month = time.getMonth() + 1;
  month = month<10?'0'+month:month;
  let date = time.getDate();
  date = date<10?'0'+date:date;
  let hours = time.getHours();
  hours = hours<10?'0'+hours:hours;
  let minutes = time.getMinutes();
  minutes = minutes<10?'0'+minutes:minutes;
  return `${year}-${month}-${date} ${hours}:${minutes}:00`
}
export {
  isIdtitfy,
  isIdtitfyReg,
  isTel,
  isPsd,
  transformDateTime
}